import { DatePicker, PaginationProps, Table } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Dayjs } from "dayjs";
import { CSVLink } from "react-csv";
import LoadingDistribute from "../DistributesAirdrop/ExportData/LoadingDistribute";
import { toCSVFormat } from "app/utils/common";
import myriaVerseApi from "app/service/myriaVerseApi";
import { API_KEY } from "app/utils/constant";
interface currentPage {
  page: number | undefined;
  pageSize: number | undefined;
}

export const numberOfData = 2;

type NodeStats = {
  user_id: string;
  wallet_id: string;
  cycle_date: string;
  node_id: string;
  points: number;
  uptime: number;
  reward_status: string;
  claim_type: string;
  token_reward: string;
  transaction_id: string;
  is_manual_claim: boolean;
};
const Nodes: React.FC<{}> = () => {
  const [address, setAddress] = useState<string>("");
  const [cycleDate, setCycleDate] = useState<Dayjs | null>(null);

  const {
    data: nodesStats,
    isLoading,
    error,
  } = useQuery(
    ["nodes", address, cycleDate?.format("YYYY-MM-DD")],
    async () => {
      const { data } = await myriaVerseApi.post<{ data: NodeStats[] }>(
        "/v1/nodes/api/admin/operations/history",
        {
          wallet_id: address,
          cycle_date: cycleDate?.format("YYYY-MM-DD"),
        },
        {
          headers: {
            "x-api-key": API_KEY || "",
          },
        }
      );

      return data?.data;
    },
    {
      enabled: Boolean(address) && Boolean(cycleDate),
    }
  );

  const [currentPage, setCurrentPage] = useState<currentPage>({
    page: undefined,
    pageSize: undefined,
  });

  //Handle change page in pagination to set current page
  const onChangePage: PaginationProps["onChange"] = (page: number, pageSize: number) => {
    setCurrentPage({ ...currentPage, page, pageSize });
  };

  const columns: {
    title: string;
    dataIndex: keyof NodeStats;
    render?: any;
  }[] = [
    {
      title: "Node Licenses",
      dataIndex: "node_id",
    },
    {
      title: "Cycle Date",
      dataIndex: "cycle_date",
      // render: (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.sender),
    },
    {
      title: "Points",
      dataIndex: "points",
      // render: (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.receiverWalletAddress),
    },
    {
      title: "Up-Time (Minutes)",
      dataIndex: "uptime",
      // render: (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.smartContract),
    },
    {
      title: "Token Reward Status",
      dataIndex: "reward_status",
      // render: (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.tokenId),
    },
    {
      title: "Was manual claim",
      dataIndex: "is_manual_claim",
      render: (text: any, record: any) => (text ? "Yes" : "No"),
    },
    {
      title: "Token Reward Volume",
      dataIndex: "token_reward",
      // render: (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.tokenId),
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      // render: (text: any, record: any) => handleRenderColumn(text, record, dataIndexDefault.tokenId),
    },
  ];

  return (
    <div className="min-w-[992px]">
      <div className="flex justify-center items-center text-2xl font-semibold pt-4 mb-10">
        <h1 className="mx-4">Nodes</h1>
      </div>
      <div className="flex w-full mb-12">
        <div className="flex justify-between items-center text-yellow-600">
          <p>Ethereum address:</p>
          <input name="address" className="w-[360px] ml-3 text-black rounded-md outline-none p-1" onChange={(e) => setAddress(e.target.value)}></input>
        </div>
        <div className="flex ml-10 justify-between items-center text-yellow-600">
          <p>Cycle Date:</p>
          <DatePicker
            className="block ml-3"
            value={cycleDate}
            onChange={(date) => {
              setCycleDate(date);
            }}
          />
        </div>
      </div>
      <div className="mt-5 flex flex-col items-end">
        {nodesStats && nodesStats.length && (
          <CSVLink
            filename={new Date(Date.now()).toLocaleString().split(",")[0].replaceAll("/", "_") + "_nodeStats.csv"}
            className={`mb-4 w-[200px] download-excel-button hover:opacity-80 hover:text-white ${isLoading && error ? "pointer-events-none" : ""}`}
            target="_blank"
            data={toCSVFormat(nodesStats)}
          >
            <span>Download CSV</span>
            <img src="/assets/images/csvIcon.png" alt="csvIcon" />
          </CSVLink>
        )}
        <Table
          loading={isLoading}
          dataSource={nodesStats || []}
          columns={columns}
          pagination={{
            position: ["bottomRight", "topRight"],
            onChange: onChangePage,
            defaultPageSize: 100,
          }}
        />
      </div>
      {isLoading ? <LoadingDistribute /> : null}
    </div>
  );
};

export default Nodes;
